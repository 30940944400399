<template>
  <div>
    <b-button v-b-toggle.sidebar-right class="top-right-btn-car">
      <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span
    ></b-button>
    <b-sidebar id="sidebar-right" title="" right shadow>
      <div class="sidebar">
        <div class="header-logo_area">
          <!-- <router-link to="/">
            <img
              src="assets/images/menu/logo/2.png"
              alt="Logo"
              style="width: 165px"
          /></router-link> -->
           <router-link :to="{ name: 'Home'}">
               <img
              src="assets/images/menu/logo/2.png"
              alt="Logo"
              style="width: 165px"></router-link >            
        </div>
        
        <router-link :to="{ name: 'Home'}"
                                > <i class="fas fa-home" aria-hidden="true"></i> Home</router-link > 
          <router-link :to="{ name: 'Auction'}"
                                > <i class="fa fa-gavel" aria-hidden="true"></i> Auctions</router-link > 
        <!-- <router-link to="Auction">
          <i class="fa fa-gavel" aria-hidden="true"></i> Auctions</router-link> -->
        

        <!-- <router-link to="Details"
          ><i class="fas fa-info-circle"></i> Details</router-link
        > -->
        <div v-if="!currentUser">
        <router-link to="Login"
          ><i class="fas fa-sign-in-alt"></i> Login</router-link
        >

        <router-link to="Register"
          ><i class="fa fa-user" aria-hidden="true"></i> Register</router-link
        >
        </div>
        <!-- <router-link to="Checkout"
          ><i class="fas fa-shopping-cart"></i> Checkout</router-link
        > -->
       <div v-if="currentUser">
        <router-link to="MyAccount"
          ><i class="fas fa-user-circle"></i> My Account</router-link
        >
        </div>
        <router-link to="About"
          ><i class="fas fa-address-card"></i> About Us</router-link
        >
         <div v-if="currentUser" class="navbar-nav ml-auto">  
                 
        <li class="nav-item">
          <a class="nav-link" href @click.prevent="logOut"> <i class="fas fa-user-circle"></i>LogOut
          </a>
        </li>
      </div>    
        <div class="input-group mb-2">
          <input
            type="text"
            class="form-control"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/Login').catch(()=>{"sidebar"});
    }
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
}

/* Sidebar links */
.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
  background-color: #99afa7;
  color: white;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
/* @media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
} */

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 1000px) {
  .sidebar a {
    text-align: left;
    float: none;
  }
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
}
.header-logo_area {
  background-color: rgb(48, 87, 216);
}
.input-group {
  width: 90%;
  margin: 0px auto;
}
.btn-secondary {
  color: #fff;
  background-color: #00000000;
  border-color: #6c757d00;
  border: none;
  font-size: 19px;
}
</style>
