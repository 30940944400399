var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mp-sec"},[_vm._m(0),_c('carousel',{attrs:{"responsive":{
      0: {
        items: 1,
        nav: false,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        
      },
      600: {
        items: 4,
        nav: false,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
      },
    }}},[_c('div',{},[_c('article',{staticClass:"pdtSliderhome"},[_c('div',[_c('div',[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"100%"},attrs:{"src":"/assets/images/car-image.png"}})]),_c('div',{staticClass:"leftright10pad"},[_c('h6',[_vm._v("Maruthi Susuki Wahon R")])]),_c('div',{staticClass:"leftright10pad text-center pdtTileTimer"},[_c('span',{},[_vm._v("Time Left : "),_c('span',{attrs:{"id":"counterDemo"}})])]),_c('div',{staticClass:"leftright10pad"},[_c('div',{staticClass:"pdtHomelistrow"},[_c('div',{staticClass:"pdtHomelistcolleft"},[_vm._v("Heading")]),_c('div',{staticClass:"pdtHomelistcolright"},[_vm._v("Heading")])]),_c('div',{staticClass:"pdtHomelistrow"},[_c('div',{staticClass:"pdtHomelistcolleft"},[_vm._v("Heading")]),_c('div',{staticClass:"pdtHomelistcolright"},[_vm._v("Heading")])]),_c('div',{staticClass:"pdtHomelistrow"},[_c('div',{staticClass:"pdtHomelistcolleft"},[_vm._v("Heading")]),_c('div',{staticClass:"pdtHomelistcolright"},[_vm._v("Heading")])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"mb-30"},[_vm._v("Today's Auctions")])])}]

export { render, staticRenderFns }