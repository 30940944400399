import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue"; 
import store from "../store/index"
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/Auction",
    name: "Auction",
    component: () => import("../views/Auction.vue"),
  },
  {
    path: '/CarDetails/:url_key',
    name: "CarDetails",
    component: () => import("../views/Details.vue"),
    props:true
  },
  {
    path: '/AuctionList',
    name: "AuctionList",
    component: () => import("../components/CarList.vue"),
    props:true
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
   
  },
  {
    path: "/Register",
    name: "Register",
    component: Register
  
  },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   // lazy-loaded
  //   component: () => import('../views/Profile.vue')
  // },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    // lazy-loaded
    component: () => import('../views/Forgotpassword.vue')
  },
  // {
  //   path: '/changepassword',
  //   name: 'changepassword',
  //   // lazy-loaded
  //   component: () => import('../views/Changepassword.vue')
  // },
  {
    path: '/admin',
    name: 'admin',
    // lazy-loaded
    component: () => import('../views/BoardAdmin.vue')
  },
  {
    path: '/mod',
    name: 'moderator',
    // lazy-loaded
    component: () => import('../views/BoardModerator.vue')
  },
  {
    path: '/user',
    name: 'user',
    // lazy-loaded
    component: () => import('../views/BoardUser.vue')
  },
  {
    path: "/Checkout",
    name: "Checkout",
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/MyAccount",
    name: "MyAccount",
    component: () => import("../views/MyAccount.vue"),
  },
  {
    path: "/testcard",
    name: "testcard",
    component: () => import("../views/testcard.vue"),
  },
  {
    path: "/testcrd",
    name: "testcrd",
    component: () => import("../views/testcrd.vue"),
    props:true
  },
  // {
  //   path: "/admin",
  //   name: "admin",
  //   component: () => import("../views/admin.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/Login')
  } else {
    next()
  }
})

export default router;
