import axios from 'axios';
import authHeader from './auth-header';
import config from '../config';


const API_URL = config.BASE_URL;
// const API_URL = 'http://localhost:8080/ibidzapi/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  get(id) {
    return axios.get(API_URL + '?sp=getCustomerProfileById&custId='+ id, { headers: authHeader()});
    
    
  }
  
  update(id, data){    
    let payload = {
      sp :"updCustomerProfile",
      fullName: data.custName,
      email: data.custEmail,
      phone: data.custPhone,
      custId: id
    }

    return axios.post(API_URL ,payload, { headers: authHeader()});

  }
  addToWhishlist(id, data){
    let payload = {
      sp :"insCustomerWishlists",      
      custId: id,
      vehId : data.vehId,  
    }

    return axios.post(API_URL ,payload, { headers: authHeader()});

  }
  addbid(aucId,custId ,bidamount){
    let payload ={   
      sp:"spBid",
      custId:custId,
      aucId:aucId,
      bidAmount: bidamount
  }

    return axios.post(API_URL ,payload, { headers: authHeader()});

  }
  rmvfromWhishlist(data){ 

    return axios.post(API_URL ,data, { headers: authHeader()});

  }
  changepassword(custId,data){ 
    
    let payload = {   
      "sp":"changePassword",
      "custId":custId,
      "currentPassword": data.Currentpassword,
      "newPassword": data.Newpassword
    } 
     return axios.post(API_URL ,payload, { headers: authHeader()});

  }
  forgotpassword(data){ 
    
    if( data.email != null || data.email != '' )
    {
     this.userinput = data.email
     
    }
    if(data.phone != null || data.phone != '')
      {
        this.userinput = data.phone
      }
      let payload = {   
        "sp":"forgotPassword",      
        "username": this.userinput,      
      } 
      return axios.post(API_URL ,payload, { headers: authHeader()});
    
    
     

  }
  forgotpasswordotp(data,item){ 
    
   
      let payload = {   
        "sp":"resetPassword",  
        "custId":item.custId,
        "otp":data.otp,
        "password":data.Newpassword    
      } 
      return axios.post(API_URL ,payload, { headers: authHeader()});
    
    
     

  }
  getAuctionList(customerId,searchkey){ 
    
   
    let payload ={
      "sp": "searchVehicles",
      "searchkey":searchkey,    
        "custId": customerId
    }
      return axios.post(API_URL ,payload);
    
    
     

  }
  getvehbyId(id) {
 return axios.get(API_URL + '?sp=getAuctionsList&vehId='+ id, { headers: authHeader()});
 
 
}
getauctionbyname(id) {
 return axios.get(API_URL + '?sp=getAuctionDetailsbyId&aucName='+ id);
 
 
}
} 

export default new UserService();