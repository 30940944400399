<template>
  <div>
    <div>
      <section
        class="loginformSection"
        style="background-image: url(assets/images/login-back.jpg)"
      >
        <div class="row nomp">
          <div class="col-md-12 nomp">
            <div class="row nomp">
              <div class="col-md-6 nomp">
                <div>
                  <div class="loginWelcome">
                    <h1>Welcome back!</h1>
                    <h5>
                      You can sign in to access<br />
                      with your exciting account
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6 backwhite nomp">
                <div>  <div class="card card-container">
      <!-- <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      /> -->
      <form name="form" @submit.prevent="handleRegister">
        <div v-if="!successful">
          <div class="form-group">
            <label for="username">Username</label>
            <input
              v-model="user.username"
              v-validate="'required|min:3|max:20'"
              type="text"
              class="form-control"
              name="username"
            />
            <div
              v-if="submitted && errors.has('username')"
              class="alert-danger"
            >{{errors.first('username')}}</div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="user.email"
              v-validate="'required|email|max:50'"
              type="email"
              class="form-control"
              name="email"
            />
            <div
              v-if="submitted && errors.has('email')"
              class="alert-danger"
            >{{errors.first('email')}}</div>
          </div>
          
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              v-model="user.phone"
              v-validate="'required|min:10|max:10'"
              type="phone"
              class="form-control"
              name="phone"
            />
            <div
              v-if="submitted && errors.has('phone')"
              class="alert-danger"
            >{{errors.first('phone')}}</div>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input
              v-model="user.password"
              v-validate="'required|min:6|max:40'"
              type="password"
              class="form-control"
              name="password" 
            />
            <div
              v-if="submitted && errors.has('password')"
              class="alert-danger"
            >{{errors.first('password')}}</div>
          </div>
          <div class="form-group" style="margin-bottom: 0px;">
            <button class="btn btn-primary btn-block loginBtn">Sign Up</button>
          </div>
        </div>
      </form>
         <div class="form-group">
          <label for="password"> <router-link to="Login">Already registered? Please Login</router-link></label>
        </div>  
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >{{message}}</div>
    </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>  
  </div>
</template>
<script>
import User from '../models/user';

export default {
  name: 'Register',
  data() {
    return {
      user: new User('', '', ''),
      submitted: false,
      successful: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
     
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/MyAccount').catch(()=>{"register"});
    }
  },
  methods: {
    handleRegister() {
      this.message = '';
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          this.$store.dispatch('auth/register', this.user).then(
            data => {
              this.message = data.Message;
              this.successful = true;
              this.$router.push('/MyAccount').catch(()=>{"handleregister"});
            },
            error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              this.successful = false;
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.loginBtn
{
      background-image: linear-gradient(to right, #000E88, #9000A7 );
      border:none;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 370px !important;
  padding: 40px 40px;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border:none;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>