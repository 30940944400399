<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="5000"
      controls
      indicators
      class="d-block w-100"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        img-src="https://i.picsum.photos/id/991/1030/250.jpg?hmac=HBeYZkD3V-RavofeNQ-SVtIZnNJS_3JGEed_GEeJM9c"
      >
        <!-- <span class="carlet-text_color"
          >We have the part you need
          <h3>STEP ON-BOARD, BID ONLINE</h3>
          <a class="uren-btn" href="">Read More</a></span
        > -->
      </b-carousel-slide>

      <!-- Slides with custom text -->
      <b-carousel-slide
        img-src="https://i.picsum.photos/id/357/1030/250.jpg?hmac=mwVpFnV7XMCALPYhoZejhxtuE9IaBrYaUJt89R0Qc_Q"
      >
        <!-- <span class="carlet-text_color"
          >We have the part you need
          <h3>STEP ON-BOARD, BID ONLINE</h3>
          <a class="uren-btn" href="">Read More</a>
        </span> -->
      </b-carousel-slide>
      <!-- Slides with custom text -->
      <b-carousel-slide
        img-src="https://i.picsum.photos/id/380/1030/250.jpg?hmac=usikBVeLMSvFRW1ZJW6lxS3h4c5xyUQrt1Xzi9XvXj4"
      >
        <!-- <span class="carlet-text_color"
          >We have the part you need
          <h3>STEP ON-BOARD, BID ONLINE</h3>
          <a class="uren-btn" href="">Read More</a></span
        > -->
      </b-carousel-slide>
      <!-- Slides with custom text -->
      <b-carousel-slide
        img-src="https://i.picsum.photos/id/571/1030/250.jpg?hmac=8gP7Vrm_r3uOXBfI77UAgwQeKWE-6_hnmrgavOviJ_o"
      >
        <!-- <span class="carlet-text_color"
          >We have the part you need
          <h3>STEP ON-BOARD, BID ONLINE</h3>
          <a class="uren-btn" href="">Read More</a></span
        > -->
      </b-carousel-slide>

      <!-- Slides with image only -->
      <!-- <b-carousel-slide
        img-src="https://picsum.photos/1024/480/?image=58"
      ></b-carousel-slide> -->

      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!-- <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="https://picsum.photos/1024/480/?image=55"
            alt="image slot"
          />
        </template>
      </b-carousel-slide> -->

      <!-- Slide with blank fluid image to maintain slide aspect ratio -->
      <!-- <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          eros felis, tincidunt a tincidunt eget, convallis vel est. Ut
          pellentesque ut lacus vel interdum.
        </p>
      </b-carousel-slide> -->
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.b-carousel {
  width: 300px;
  height: 300px;
}
</style>
