<template>

<div>  
  <div v-if="!items.length">
             
  </div>
  <section style="padding: 60px 0px">
        <div class="container bidz-container-list"  v-if="items.length" >
           
          <div class="pdtListingWrap"  v-for="item in items" :key="item.vehId"  >
            <div class="row" >
              <div class="col-md-2">
                <div class="bidlistImagewrap">
                  <img
                    :src="path+ '/'+item.vehImage1" 
                    class="img-fluid img-responsive border5px"
                  />
                </div>
              </div>
              <div class="col-md-7 bidborderright">
                <div class="row">
                  <div class="bidlistDetailswrapwidth85">
                    <div class="bidlistDetailswrap">
                      <a href="#"
                        ><h4> 
                          {{ item.brandName}}
                          <span class="cModalname">
                            {{ item.modelName}}
                             </span>
                        </h4></a
                      >
                    </div>
                  </div>
                  <div class="bidlistDetailswrapwidth15">
                    <div v-if="item.isWishlisted == 1" >
                        <div class="button-bidzzz active"   @click="rmvfromWhishlist(item)">
                          <svg width="35px" height="25px" xmlns="http://www.w3.org/2000/svg">
                           <g fill="none" fill-rule="evenodd">
                             <path class="heart-stroke" d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z" fill="#0090E3"/>
                             <path class="heart-full" d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z" fill="red"/>
                             <path class="heart-lines" d="M26,4 L30.6852129,0.251829715" stroke="red" stroke-width="2" stroke-linecap="round"/>
                             <path class="heart-lines" d="M2.314788,4 L7.00000086,0.251829715" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 10.314788 1)"/>
                             <path class="heart-lines" d="M27,12 L33,12" stroke="red" stroke-width="2" stroke-linecap="round" />
                             <path class="heart-lines" d="M0,12 L6,12" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 7 1)"/>
                             <path class="heart-lines" d="M24,19 L28.6852129,22.7481703" stroke="red" stroke-width="2" stroke-linecap="round"/>
                             <path class="heart-lines" d="M4.314788,19 L9.00000086,22.7481703" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 14.314788 1)"/>
                           </g>
                          </svg>
                      </div>
                    </div>
                    <div  v-else-if ="item.isWishlisted != 1">
                      <div class="button-bidzzz"   @click="addorRemove(item)">
                          <svg width="35px" height="25px" xmlns="http://www.w3.org/2000/svg">
                           <g fill="none" fill-rule="evenodd">
                             <path class="heart-stroke" d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z" fill="#0090E3"/>
                             <path class="heart-full" d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z" fill="red"/>
                             <path class="heart-lines" d="M26,4 L30.6852129,0.251829715" stroke="red" stroke-width="2" stroke-linecap="round"/>
                             <path class="heart-lines" d="M2.314788,4 L7.00000086,0.251829715" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 10.314788 1)"/>
                             <path class="heart-lines" d="M27,12 L33,12" stroke="red" stroke-width="2" stroke-linecap="round" />
                             <path class="heart-lines" d="M0,12 L6,12" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 7 1)"/>
                             <path class="heart-lines" d="M24,19 L28.6852129,22.7481703" stroke="red" stroke-width="2" stroke-linecap="round"/>
                             <path class="heart-lines" d="M4.314788,19 L9.00000086,22.7481703" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 14.314788 1)"/>
                           </g>
                          </svg>
                      </div>                     
                    </div>
                  </div>
                </div>
                <div class="row hidinglast2on768-991">
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="fas fa-tachometer-alt pdtListico"></i>
                        <div class="pdtListicon">
                          <span>KM </span>
                          <p class="fontWeightbidlist">
                             {{ item.kmClocked }} 
                             </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="fas fa-map-marker-alt pdtListico"></i>
                        <div class="pdtListicon">
                          <span>Location</span>
                          <p class="fontWeightbidlist">
                            {{ item.locName }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="far fa-calendar-alt pdtListico greenIcon"></i>
                        <div class="pdtListicon">
                          <span>End Date</span>
                          <p class="fontWeightbidlist greenIcon"> 
                            {{ item.aucMatDate }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="far fa-clock pdtListico greenIcon"></i>
                        <div class="pdtListicon">
                          <span>Time Left</span>
                          <p class="fontWeightbidlist greenIcon">
                            {{item.aucMatDate}}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <span>Min. Inc.</span>
                          <p class="fontWeightbidlist">
                           ₹ {{item.aucMinBid}}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <a href=""
                            >
                            <div class="numberplate" v-if="!currentUser">
                             {{item.vehRegNo | truncate(5)}} ****                              
                            </div>
                            <div class="numberplate" v-if="currentUser">
                             {{item.vehRegNo}}                          
                            </div>
                            </a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p hideon768-991 bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <a href=""><div class="bidNumberbtn">
                            {{ item.bidzCount}} BIDS
                            </div></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p hideon768-991 bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">

                          <router-link :to="{ name: 'CarDetails', params: { url_key: item.aucName }}"
                                ><div class="bidViewDetails">
                              View Details
                            </div></router-link >                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="text-center">
                  <div class="marginbidtop">
                    <span>CURRENT PRICE</span>
                    <div>
                       <h4 v-if="item.latestBidAmount == null">
                         ₹ 0
                        </h4>
                       <h4 v-else> ₹ {{ item.latestBidAmount}}</h4>
                       </div>
                     <div class="marginbidtop">
                        <b-button v-b-modal.modalbidz class="bidBtn"  @click=" sendInfo(item)">BID NOW</b-button>
                    
                     </div> 
                  </div>
                  <div>                  
                    <div>
                    <div class="row">
                      <div class="col">
                        <div>
                          <a href=""><div class="bidzNumberz">
                              {{item.bidzCount}}
                            </div></a>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <router-link to="Details"
                            ><div class="detailsButtonz">
                              Details
                            </div></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
          
          <div class="overflow-auto"> 
               <div class="mt-3">                  
                  <b-pagination
                     v-model="currentPage"
                     :total-rows="totalRows" 
                     :per-page="perPage"
                     align="center"
                    
                     pills 
                     first-number
                     last-number                     
                  ></b-pagination>
                </div>
           </div> 
           <div>    
    <b-modal
      id="modalbidz"
      ref="modal"  hide-footer  v-if="currentUser"     
    >
      <form ref="form">
      <p for="bidamount" class="bidamountzz" v-if="selectedUser.latestBidAmount == null || selectedUser.latestBidAmount ==''">Latest Bid Amount: ₹ 0  </p>
        <p for="bidamount" class="bidamountzz" v-else>Latest Bid Amount:  ₹ {{ selectedUser.latestBidAmount }}</p>
         <input type="test" class="form-control" id="bidamount" v-model="bid" placeholder="Enter your bid amount" name="bidamont" style="width:100%;">
        <button type="button"  class="btn subBtnzz" @click="bidentry(selectedUser)" style="width: 100%;margin-top: 10px;">SUBMIT</button>
                      <div class="form-group" v-if="message == 'Success'"> 
                      <div  class="alert alert-warning" role="alert" v-on="$bvModal.hide('modalbidz')">{{message}}</div>
                       
                      </div>
                      <div class="form-group" v-else-if="message"> 
                      <div  class="alert alert-warning" role="alert">{{message}}</div>
                      </div>
      </form> 
      
    </b-modal>


    <b-modal
      id="modalbidz"
      ref="modal"  hide-footer  v-else-if="!currentUser"     
    >
      <form ref="form">
       
        <div>
                                    <p for="bidamount" class="bidamountzz">Login to BID </p>
                                 </div>
                                
                                <!-- <input type="test" class="form-control" id="bidamount" v-model="bid" placeholder="Enter your bid amount" name="bidamont" style="width:100%;"> -->
                            
                        <button type="submit"  class="btn subBtnzz" @click="gotologin()" style="width: 100%;margin-top: 10px;">Go To Login</button>

                    
      </form>   
    </b-modal>
  </div>

                   <!-- modal -->
         
        </div> 
                  
      </section>        
           </div>
</template>

<script>

import userService from '../services/user.service';
import Config from  '../config';
import axios from "axios";
//import user from '../models/localstorage'
//import $ from 'jquery'
export default {
   name: "ProductCard", 
//    created() {
//     this.url_key = this.$route.params.url_key;
//      console.log(this.search_urlkey  + "  url key created" );
//      console.log(this.item);
// },

props: ["changed_url"],
watch : {
     '$route.query.search'() {
       console.log(this.$route.query.search);
       this.url_key =this.$route.query.search;
                this.getAuctionList();
            }

      //   console.log('inside');
      // this.getAuctionList(url_key);
             //  },
               },
 
data()
{
  return{
      lists: [],     
        perPage: 2,
        currentPage: 1,
        path: Config.BASE_URL_IMG ,
        currentlist:'',
        message:'',
         modalShow: false,
         bid:'',
         customerId:'',
         st:'',
         selectedUser: '',        
         }

},
 filters: {
        truncate: function(data,num){
            const reqdString = 
              data.split("").slice(0, num).join("");
            return reqdString;
        }
    },
computed: {
        currentUser() {
        
      return this.$store.state.auth.user;

    },
   
    items () {     
      
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    totalRows () {
      return this.lists.length
    }
  },
mounted()
    {
      console.log(this.currentUser);
      //console.log(this.$route.query.search);
      
        if(this.currentUser && ! this.$route.query.search )
        {
          
          this.st = this.currentUser[0].customerId;
           this.getCarList();
      
        }else if(this.$route.query.search)
        {
          this.url_key=  this.$route.query.search ;
          console.log(this.url_key);
            this.getAuctionList();
        }else
        {
             this.getCarList();
        } 
        
        // this.getAuctionList();
         
    },
   methods :{
    
          getCarList(){
            if(this.st){
              
              this.customerId =this.st;
            }else {
               this.customerId = '';
            } 
            axios({
      method: "Get",
      url: Config.BASE_URL+"?sp=getAuctionsList&custId="  + this.customerId  
    }).then(resp => {
       this.lists = resp.data.Data;
    }).catch(err => {
      console.log(err.response)
    })
     },
     getAuctionList(){
       
            if(this.url_key){              
              this.searchkey = this.url_key;             
              if(this.st){
                this.customerId =this.st;  
                console.log(this.customerId + "get auction customer id");

              }else{
                 this.customerId = '';
              }               
            }
      userService.getAuctionList(this.customerId,this.searchkey).then(resp => {
       this.lists = resp.data.Data;
       console.log(this.lists)
    }).catch(err => {
      console.log(err.response)
    })
     },
     addorRemove(elem){


       if(this.currentUser)
       {
           let cusid =this.$store.state.auth.user[0].customerId;   
           console.log(this.$store.state.auth.user);                  
        userService.addToWhishlist(cusid,elem).then(response =>{
          console.log(response.data);
         this.message =' added to whishlist'
           this.getCarList();
        })
       }
       else
       {
         this.$router.push('/MyAccount').catch(()=>{"carlist"});
       }
           
  
      },      

      bidentry(item){
       
        if( this.st && this.bid !=='' && this.bid !== null){
          let cusid = this.st;
        let bidamount = parseInt(this.bid); 
        userService.addbid(item.aucId,cusid,bidamount).then(response =>{
          console.log(response.data.Message);
         this.message = response.data.Message
         this.getCarList(); 
        }).catch(error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
                })  

        }
        else
        {
          this.message ='Field is requied'
        }
        

      },
     
      gotologin(){
         this.$router.push('/login').catch(()=>{"login"});

      },
     rmvfromWhishlist(item){
       console.log(this.currentUser[0].customerId);
                 let cusid =this.currentUser[0].customerId;  

                   let payload = {   
              "sp":"delCustomerWishlists",
              "custId":cusid,
              "vehId":item.vehId
            }
        userService.rmvfromWhishlist(payload).then(response =>{
          console.log(response.data);
           this.getCarList();
        })    
     },
      sendInfo(item) {        
        this.selectedUser = item;
    },
    // storetostore(item){
      
      
    //   //localStorage.setItem('auction', JSON.stringify(item));
    //   // <router-link :to="{ name: 'Details', params: {  } }" >
    //   this.$router.push({
    //       name: 'Details',
    //   params: { data: item.aucName}
    //   });

    // }
   },  
    
  
}
</script>

<style scoped>
.subBtnzz
{
      background-image: linear-gradient(to bottom right, #af0000, #ff3a3a);
    color: white;
    font-weight: 600;
}
.bidamountzz
{
  font-size: 20px;
    font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
          animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
          animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
          animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>