<template>
  <div>    
       <singleslider></singleslider>
    <homeloginsection></homeloginsection>
    <banner></banner>
    <section>
      <selectioncontainer></selectioncontainer>
    </section><section >
      <midbanner></midbanner>
      <selectioncontainer></selectioncontainer>
      <brandarea></brandarea>
    </section>

    <!-- <showmodel></showmodel> -->
  
  </div>
</template>

<script>
//import UserService from '../services/user.service';

export default {
  name: 'Home',
  data() {
    return {
      content: ''
    };
  },
   computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    // if (this.loggedIn) {
    //   this.$router.push('/Home').catch(()=>{"home"});
    // } 
  },
  // mounted() {
  //   UserService.getPublicContent().then(
  //     response => {
  //       this.content = response.data;
  //     },
  //     error => {
  //       this.content =
  //         (error.response && error.response.data) ||
  //         error.message ||
  //         error.toString();
  //     }
  //   );
  // }
};
</script>