<template>
  <div>
    <hooper group="group1">
      <slide>
        <button @click="showSingle"></button>
        <vue-easy-lightbox
          :visible="visible"
          :imgs="imgs"
          :index="index"
          @hide="handleHide"
        >
        </vue-easy-lightbox>
      </slide>
    </hooper>
    <hooper group="group1" :itemsToShow="3" :centerMode="true">
      <slide>
        <div>
          <img src="/assets/images/car1.jpg" alt="Product Image" />
        </div>
      </slide>
      <slide>
        <div>
          <img src="/assets/images/car2.jpg" alt="Product Image" />
        </div>
      </slide>
      <slide>
        <div>
          <img src="/assets/images/car1.jpg" alt="Product Image" />
        </div>
      </slide>
      <slide>
        <div>
          <img src="/assets/images/car2.jpg" alt="Product Image" />
        </div>
      </slide>

      <hooper-navigation slot="hooper-addons"></hooper-navigation>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
  </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    };
  },
  methods: {
    showSingle() {
      this.imgs = "/assets/images/car1.jpg";

      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>
<style scoped>
.hooper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>
