import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCarousel from "vue-carousel";
import { BootstrapVue } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import imageZoom from "vue-image-zoomer";
import ProductZoomer from "vue-product-zoomer";
import VueEasyLightbox from "vue-easy-lightbox";
import Axios from "axios";
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);
Vue.use(VueEasyLightbox);
Vue.use(ProductZoomer);
Vue.use(Hooper, Slide);
Vue.use(imageZoom);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.use(VueCarousel);

Vue.config.productionTip = false;
Vue.component("navbar", require("./components/Navbar.vue").default);
Vue.component("singleslider", require("./components/SingleSlider.vue").default);
Vue.component("banner", require("./components/Banner.vue").default);
Vue.component("brandarea", require("./components/BrandArea.vue").default);
Vue.component("midbanner", require("./components/midBanner.vue").default);
Vue.component("showmodel", require("./components/ShowModel.vue").default);
Vue.component("sidebar", require("./components/sidebar.vue").default);
Vue.component("carlist", require("./components/CarList.vue").default);
Vue.component("wishList", require("./components/WishList.vue").default);
Vue.component("mybidz", require("./components/MyBidz.vue").default);
Vue.component("mywinnings", require("./components/MyWinnings.vue").default);
Vue.component("changepassword", require("./views/Changepassword.vue").default);
Vue.component("comingsoon", require("./components/ComingSoon.vue").default);

//for myaccount/user section
// Vue.component("myProfile", require("./components/user/myProfile.vue").default);


Vue.component(
  "customcarousel",
  require("./components/CustomCarousel.vue").default
);

Vue.component(
  "selectioncontainer",
  require("./components/SelectionContainer.vue").default
);
Vue.component(
  "homeloginsection",
  require("./components/HomeLoginselection.vue").default
);
Vue.component("foot", require("./components/Foot.vue").default);


 Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}
new Vue({
  router,
  store,
  render: (h) => h(App), 

}).$mount("#app");



