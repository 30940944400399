<template>
  <div> 
    <div>
      <section
        class="loginformSection">       
     <div>  
    <div class="card card-container">      
      <form name="form" @submit.prevent="handlepasswordreset">
        <div v-if="!successful">
            <div class="form-group">
            <label for="password">Current Password</label>
            <input
              v-model="user.Currentpassword"
              v-validate="'required|min:6|max:40'"
              type="password"
              class="form-control"
              name="Currentpassword"
            />
            <div
              v-if="submitted && errors.has('Currentpassword')"
              class="alert-danger"
            >{{errors.first('Currentpassword')}}</div>
          </div>
           <div class="form-group">
            <label for="password"> New Password</label>
            <input
              v-model="user.Newpassword"
              v-validate="'required|min:6|max:40'"
              type="password"
              class="form-control"
              name="Newpassword"
              ref="password"
            />
            <div
              v-if="submitted && errors.has('Newpassword')"
              class="alert-danger"
            >{{errors.first('Newpassword')}}</div>
          </div>
           <div class="form-group">
            <label for="password">Re-enter Password</label>
            <input
              v-model="user.reenterpassword"
              v-validate="'required|min:6|max:40|confirmed:password'"
              type="password"
              class="form-control"
              name="reenterpassword"
              data-vv-as="password"
            />
            <div
              v-if="submitted && errors.has('reenterpassword')"
              class="alert-danger"
            >{{errors.first('reenterpassword')}}</div>
          </div>          
          <div class="form-group">
            <button class="btn btn-primary btn-block">Update</button>
          </div>
        </div>
         <div class="form-group"> 
          <div v-if="message" class="alert alert-warning" role="alert">{{message}}</div>
        </div>
      </form>       
    </div>
   </div>             
      </section>
    </div>
  </div>
</template>
<script>

import User from '../models/user';
import userService from '../services/user.service';
import user from '../models/localstorage'

export default {
  name: 'Login',
    data() {
    return {
      user: new User('', '', '',),
      submitted: false,
      successful: false,
      message: '',
     
    };
  },

   mounted() {      
    if(!this.$store.state.auth.user) {      
        this.$router.push('/login').catch(()=>{"changepassword"});   
    }else{

    this.st=user; 
   
    } 
  },
   methods: {
    handlepasswordreset() {  
    this.message = '';  
      this.submitted = true;     
      this.$validator.validate().then(isValid => {
        if (isValid) {   

          let cusid =this.st[0].customerId;             
            userService.changepassword(cusid,this.user).then(
            data => {           
            this.message = data.data.Message;
            this.successful = true;
            },
            error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              this.successful = false;
            }
          );
        }
      });
    
    }
  }
};

</script>
<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
