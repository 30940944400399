<template>
<div>
   <div v-if="!items.length">
           <comingsoon></comingsoon>
  </div>
  <section style="padding: 60px 0px">
        <div class="container bidz-container-list" v-if="items.length" >          
          <div class="pdtListingWrap"  v-for="item in items" :key="item.vehId"  >
            <div class="row" >
              <div class="col-md-2">
                <div class="bidlistImagewrap">
                  <img
                    :src="path+ '/'+item.vehImage1" 
                    class="img-fluid img-responsive border5px"
                  />
                </div>
              </div>
              <div class="col-md-7 bidborderright">
                <div class="row">
                  <div class="bidlistDetailswrapwidth85">
                    <div class="bidlistDetailswrap">
                      <a href="#"
                        ><h4> 
                          {{ item.brandName}}
                          <span class="cModalname">
                            {{ item.modelName}}
                             </span>
                        </h4></a
                      >
                    </div>
                  </div>
                  <div class="bidlistDetailswrapwidth15">
                    <div>
                      <button
                        class="favoritesListpdt js-btn-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-custom-class="tooltip-primary"
                        title="Add to Favorites"  @click="addToWhishlist()"
                      >
                        <i class="far fa-heart"></i>
                       
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row hidinglast2on768-991">
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="fas fa-tachometer-alt pdtListico"></i>
                        <div class="pdtListicon">
                          <span>KM </span>
                          <p class="fontWeightbidlist">
                             {{ item.kmClocked }} 
                             </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="fas fa-map-marker-alt pdtListico"></i>
                        <div class="pdtListicon">
                          <span>Location</span>
                          <p class="fontWeightbidlist">
                            {{ item.locName }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="far fa-calendar-alt pdtListico greenIcon"></i>
                        <div class="pdtListicon">
                          <span>End Date</span>
                          <p class="fontWeightbidlist greenIcon"> 
                            {{ item.aucMatDate }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="far fa-clock pdtListico greenIcon"></i>
                        <div class="pdtListicon">
                          <span>Time Left</span>
                          <p class="fontWeightbidlist greenIcon">
                            {{item.aucMatDate}}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <span>Min. Inc.</span>
                          <p class="fontWeightbidlist">
                            {{item.aucMinBid}}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <a href=""
                            ><div class="numberplate">
                             {{item.vehRegNo}}                               
                            </div></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p hideon768-991 bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <a href=""><div class="bidNumberbtn">
                            {{ item.bidzCount}}
                            </div></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-3 col-sm-3 bidlistwidth50p hideon768-991 bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <router-link to="Details"
                            ><div class="bidViewDetails">
                              View Details
                            </div></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="text-center">
                  <div class="marginbidtop">
                    <span>CURRENT PRIZE</span>
                    <h4>
                     {{item.latestBidAmount}}
                      </h4>
                    <div class="marginbidtop">
                      <router-link to="Details" class="bidBtn"
                        >BID NOW</router-link
                      >
                    </div>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col">
                        <div>
                          <a href=""><div class="bidzNumberz">
                              {{item.bidzCount}}
                            </div></a>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <router-link to="Details"
                            ><div class="detailsButtonz">
                              Details
                            </div></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="overflow-auto"> 
               <div class="mt-3">                  
                  <b-pagination
                     v-model="currentPage"
                     :total-rows="totalRows" 
                     :per-page="perPage"
                     align="center"
                    
                     pills 
                     first-number
                     last-number                     
                  ></b-pagination>
                </div>
           </div> 
        </div>           
      </section>


           </div>
</template>

<script>
import user from '../models/localstorage'
//import userService from '../services/user.service';
import Config from  '../config';
import axios from "axios";
export default {
   name: "ProductCard",  
 
data()
{
  return{
      lists: [],     
        perPage: 3,
        currentPage: 1,
        path: Config.BASE_URL_IMG ,
        currentlist:'',
       
  }

},
computed: {
        currentUser() {
      return this.$store.state.auth.user;
    },
   
    items () {     
      
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    totalRows () {
      return this.lists.length
    }
  },
mounted(){
  this.st =user;
       this.getCarList();  
      
  },
   methods :{
    
          getCarList : function(){
             let tokens = this.st[0].token;
            let customerId = this.st[0].customerId;
               axios({
      method: "Get",
      url: Config.BASE_URL+'?sp=getCustomerWinnings&custId=' + customerId, headers: {'token': tokens }         
    }).then(resp => {
       this.lists = resp.data.Data; 
    }).catch(err => {
      console.log(err.response)
    })
     },
     
   },
    
  
}
</script>

<style>

</style>