export default {  

    BASE_URL :'https://wpr.intertoons.net/ibidzapi/',
    BASE_URL_IMG :'https://wpr.intertoons.net/ibidzadmin/',
    BASE_URLASSETS :'https://wpr.intertoons.net/ibidz/',


//     BASE_URL :'http://localhost:8080/ibidzapi/',
//     BASE_URL_IMG :'http://localhost:8080/ibidzapiAdmin',
//    BASE_URL_Ibidz_admin :'http://localhost:8080/ibidzAdmin/', 
    
}