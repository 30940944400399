<template>
  <section class="homeLoginSec">
    <div class="container">
      <div>
        <h2 class="text-center">Have a vehicle, Register with us</h2>
      </div>
      <div class="row formwrapRow" style="padding: 100px">
        <div class="col-md-6 border-right-login">
          <div class="text-center">
            <h4>Customer Login</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <router-link class="home_login" to="Login">Login</router-link>
          </div>
        </div>
        <div class="col-md-6 loginspaceTop">
          <div class="text-center">
            <h4>Vendor Login</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <router-link class="home_login" to="Login">Login</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
