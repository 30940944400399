<template>
  <div>
    <header class="header-main_area header-main_area-2 header-main_area-3">
      <div class="header-middle_area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-lg-2 col-md-3 col-sm-5 width50mobile">
              <div class="header-logo_area">
                <router-link to="/">
                  <img
                    src="/assets/images/menu/logo/2.png"
                    alt="Logo"
                    style="width: 165px"
                /></router-link>
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 d-none d-lg-block">
              <div class="for-big-screen hm-form_area">
                <form action="#" class="hm-searchbox">
                  <select class="nice-select select-search-category">
                    <option value="0">Location</option>
                    <option value="10">Kochi</option>
                    <option value="17">Edappally</option>
                    <option value="20">Kaloor</option>
                    <option value="21">Kalamassery</option>
                  </select>
                  <input type="text" placeholder="Enter your search key ..."  v-model="search"  />            

                   <router-link :to="{ path: '/AuctionList', query: { 'search': search }}"
                                ><div class="header-search_btn">
                             <i class="ion-ios-search-strong"><span>Search</span></i>  
                            </div></router-link >
                           
                  <!-- <button class="header-search_btn" type="submit">
                    <i class="ion-ios-search-strong"><span>Search</span></i>                    
                  </button> -->
                   <!-- <input type="text" placeholder="Enter your search key ..."  v-model="search"  /> -->
                   
                </form>
              </div>
            </div>
            <div class="col-lg-4 col-md-9 col-sm-7 width50mobile">
              <div class="header-right_area">
                <ul>
                  <li class="mobile-menu_wrap d-flex d-lg-none">
                    <sidebar></sidebar>
                  </li>

                  <li class="contact-us_wrap">
                    <a href=""><i class="ion-email"></i>dummy@email.com</a>
                  </li>
                  <li class="contact-us_wrap">
                    <a href="tel://+123123321345"
                      ><i class="ion-android-call"></i>+123 321 345</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-top_area bg--primary">
        <div class="container-fluid">
          <div class="row">
            <div class="custom-category_col col-12">
              <div class="category-menu category-menu-hidden">
                <div>
                  <!-- <b-dropdown
                    size="lg"
                    id="dropdown-offset"
                    text="Category"
                    class=""
                    menu-class="w-100"
                    block
                  >
                    <b-dropdown-item
                      ><router-link to="#"></router-link>Category
                      1</b-dropdown-item
                    >
                    <b-dropdown-item
                      ><router-link to="#"></router-link>Category
                      2</b-dropdown-item
                    >
                    <b-dropdown-item
                      ><router-link to="#"></router-link>Category
                      3</b-dropdown-item
                    >
                    <b-dropdown-item
                      ><router-link to="#"></router-link>Category
                      4</b-dropdown-item
                    >
                    <b-dropdown-item
                      ><router-link to="#"></router-link>Category
                      5</b-dropdown-item
                    >
                  </b-dropdown> -->
                </div>
              </div>
            </div>

            <div class="custom-menu_col col-12 d-none d-lg-block">
              <div class="main-menu_area position-relative">
                <nav class="main-nav">
                  <ul>
                    <li class="">
                      <router-link to='/'>                                
                          Home  </router-link >
                      </li>
                    <li class="">
                      <router-link :to="{ name: 'Auction', }">                                
                            Auctions</router-link >
                    </li>
                    <!-- <li class="">
                      <router-link to="Details">Details</router-link>
                    </li> -->
                     
                    <li class=""  v-if="!currentUser">
                      <router-link :to="{ name: 'Login', }">                                
                            Login</router-link >
                    </li>   
                     <li v-if="!currentUser">
                            <router-link :to="{ name: 'Register', }">Register</router-link>
                        </li>                
                    <li class="">
                       <router-link :to="{ name: 'About', }">                                
                            About Us</router-link >
                     
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="custom-setting_col col-12 d-none d-lg-block">
              <div class="ht-right_area">
                <div class="ht-menu">
                  <ul>
                    <li>
                       <router-link :to="{ name: 'MyAccount', }">                                
                           <span class="fa fa-user"></span> <span>My Account</span
                        ><i class="fa fa-chevron-down"></i
                      ></router-link >
                      <!-- <router-link to="MyAccount"
                        ><span class="fa fa-user"></span> <span>My Account</span
                        ><i class="fa fa-chevron-down"></i
                      ></router-link> -->

                      <ul class="ht-dropdown ht-my_account">
                        <li v-if="!currentUser">
                            <router-link :to="{ name: 'Register', }">Register</router-link>
                        </li>
                        <li class="active"></li>
                        <li v-if="!currentUser">
                          <router-link :to="{ name: 'Login', }">Login</router-link >    
                        </li> 
                        <!-- <li  v-if="!currentUser">
                           <li class="" >
                                  <router-link :to="{ name: 'Register', }">Register</router-link>                         
                           </li>
                        </li> 
                        <li class="active"></li>
                        <li  v-if="!currentUser">
                            <li class="">
                              <router-link :to="{ name: 'Login', }">Login</router-link >                        
                           </li>
                        </li> -->
                   
                          <div v-if="currentUser" class="navbar-nav ml-auto">        
                          <li class="nav-item">
                            <a class="nav-link" href @click.prevent="logOut"> <i class="fas fa-user-circle"></i>LogOut
                            </a>
                          </li>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="for-tab custom-search_col col-12 d-none d-md-block d-lg-none"
            >
              <div class="hm-form_area">
                <form action="#" class="hm-searchbox">
                  <select class="nice-select select-search-category">
                    <option value="0">Location</option>
                    <option value="10">Kochi</option>
                    <option value="17">Edappally</option>
                    <option value="20">Kaloor</option>
                    <option value="21">Kalamassery</option>
                  </select>
                  <input type="text" placeholder="Enter your search key ..." />
                  <button class="header-search_btn" type="submit">
                    <i class="ion-ios-search-strong"><span>Search</span></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header Main Area End Here -->
  </div>
</template>

<script>
import sidebar from "./sidebar.vue";
import user from '../models/localstorage'
export default {


  data(){
    return{
       search:'',
    }
  },
  // created (){
  //    this.url_key = this.$route.params.url_key;
  //    console.log(this.search_urlkey  + "  url key created" );
  //    console.log(this.item);

  // },
  watch : {
      changed_url(search) {
      this.search =search ;
               },
               },
  props:{
         /* url_key: {

          type : [Number, String],
            required: true,
          },*/
          // item:{
          //   type: Object,
          //   required: true,
          // }
        },
        created(){
          this.curntUser =user[0].token;
      // console.log(this.curntUser + "token");
        },
  components: { sidebar },
  mounted(){
    this.curntUser = user[0];
    console.log(this.curntUser + "token");

  },
   computed: {
    currentUser() {
      //  console.log(this.$store.state.auth.user + "token");
      return this.$store.state.auth.user;
     
    },
    
  },
   methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/Login').catch(()=>{"navbar"});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-nav ul,
.ht-menu ul {
  margin-bottom: 0px;
}
.category-menu {
  margin-top: 3px;
}
@media screen and (min-device-width: 991px) and (max-device-width: 1180px) {
  .header-main_area-3
    .header-middle_area
    .header-right_area
    > ul
    > li.contact-us_wrap
    > a {
    color: #000000;
    font-size: 11px;
  }
}
</style>
