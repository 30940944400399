var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row uren-brand_area"},[_c('div',{staticClass:"col-lg-12"},[_vm._m(0),_c('div',{staticClass:"sp-img_area brand-home-slider"},[_c('carousel',{attrs:{"responsive":{
          0: {
            items: 1,
            nav: false,
            dots: false,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: false,
          },
          600: {
            items: 5,
            nav: false,
            dots: false,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            margin: 60,
            autoplayHoverPause: false,
          },
        }}},[_c('img',{attrs:{"src":"/assets/images/brand/volkswagen.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/mercedes-benz.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/audi.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/volkswagen.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/mercedes-benz.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/audi.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/volkswagen.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/mercedes-benz.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/audi.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/volkswagen.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/mercedes-benz.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/audi.png","alt":"Brand Image"}}),_c('img',{attrs:{"src":"/assets/images/brand/ford.png","alt":"Brand Image"}})])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Most Popular Car Makes")])])}]

export { render, staticRenderFns }