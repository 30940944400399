<template>
  <div> 
    <div>
      <section
        class="loginformSection"
        style="background-image: url(assets/images/login-back.jpg)"
      >
        <div class="row nomp">
          <div class="col-md-12 nomp">
            <div class="row nomp">
              <div class="col-md-6 nomp">
                <div>
                  <div class="loginWelcome">
                    <h1>Welcome back!</h1>
                    <h5>
                      You can sign in to access<br />
                      with your exciting account
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6 backwhite nomp">
                <div>                 
                  <div class="card card-container">
      <!-- <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      /> -->
      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">Email or  Phone</label>
          <input
            v-model="user.username"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="username"
          />
          <div
            v-if="errors.has('username')"
            class="alert alert-danger"
            role="alert"
          >Username is required!</div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            v-model="user.password"
            v-validate="'required'"
            type="password"
            class="form-control"
            name="password"
          />
          <div
            v-if="errors.has('password')"
            class="alert alert-danger"
            role="alert"
          >Password is required!</div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block loginBtn" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>LOGIN</span>
          </button>
        </div>
        <div class="form-group">
          <label for="password"> <router-link to="Register">Register / </router-link>  <a href="/forgotpassword"  rel="noopener noreferrer">Forgot Password ?</a>  </label>
        </div>        
        <div class="form-group"> 
          <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
        </div>
      </form>
    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import User from '../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/MyAccount').catch(()=>{"login"});
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/Auction').catch(()=>{"handlelogin"});
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  }
};

</script>
<style scoped>
.loginBtn
{
      background-image: linear-gradient(to right, #000E88, #9000A7 );
      border:none;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 380px !important;
  padding: 40px 40px;
  margin-bottom: 60px;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
      border: none;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
