<template>
  <!-- Begin Footer Area -->
  <div class="uren-footer_area">
    <div class="footer-top_area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="newsletter-area">
              <h3 class="title">Join Our Newsletter Now</h3>
              <p class="short-desc">
                Get E-mail updates about our latest shop and special offers.
              </p>
              <div class="newsletter-form_wrap">
                <form
                  action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="newsletters-form validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <div id="mc-form" class="mc-form subscribe-form">
                      <input
                        id="mc-email"
                        class="newsletter-input"
                        type="email"
                        autocomplete="off"
                        placeholder="Enter your email"
                      />
                      <button class="newsletter-btn" id="mc-submit">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-middle_area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4">
            <div class="footer-widgets_info">
              <div class="footer-widgets_logo">
                <a href="#">
                  <img src="/assets/images/menu/logo/2.png" alt=" Footer Logo" />
                </a>
              </div>
              <div class="widget-short_desc">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div class="widgets-essential_stuff">
                <ul>
                  <li class="uren-address">
                    <span>Address:</span> TRaver Croft Drive Knoxville, 37921
                  </li>
                  <li class="uren-phone">
                    <span>Call Us:</span>
                    <a href="tel://+123123321345">+91 9087 654 321</a>
                  </li>
                  <li class="uren-email">
                    <span>Email:</span>
                    <a href="mailto://dummy@mail.com">dummy@mail.com</a>
                  </li>
                </ul>
              </div>
              <div class="uren-social_link">
                <ul>
                  <li class="facebook">
                    <a
                      href="https://www.facebook.com/"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Facebook"
                    >
                      <i class="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li class="twitter">
                    <a
                      href="https://twitter.com/"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Twitter"
                    >
                      <i class="fab fa-twitter-square"></i>
                    </a>
                  </li>
                  <li class="google-plus">
                    <a
                      href="https://www.plus.google.com/discover"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Google Plus"
                    >
                      <i class="fab fa-google-plus"></i>
                    </a>
                  </li>
                  <li class="instagram">
                    <a
                      href="https://rss.com/"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Instagram"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="footer-widgets_area">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Information</h3>
                  </div>
                  <div class="footer-widgets">
                    <ul>
                      <li><a href="javascript:void(0)">About Us</a></li>
                      <li>
                        <a href="javascript:void(0)">Delivery Information</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Information</h3>
                  </div>
                  <div class="footer-widgets">
                    <ul>
                      <li><a href="javascript:void(0)">About Us</a></li>
                      <li>
                        <a href="javascript:void(0)">Delivery Information</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Information</h3>
                  </div>
                  <div class="footer-widgets">
                    <ul>
                      <li><a href="javascript:void(0)">About Us</a></li>
                      <li>
                        <a href="javascript:void(0)">Delivery Information</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Information</h3>
                  </div>
                  <div class="footer-widgets">
                    <ul>
                      <li><a href="javascript:void(0)">About Us</a></li>
                      <li>
                        <a href="javascript:void(0)">Delivery Information</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom_area">
      <div class="container-fluid">
        <div class="footer-bottom_nav">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="copyright">
                  <span
                    ><a style="color: white" href="templateshub.net"
                      >Developed by Intertoons Pvt. Ltd.</a
                    ></span
                  >
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="payment">
                  <a href="#">
                    <img
                      src="/assets/images/footer/payment/1.png"
                      alt="Uren's Payment Method"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Area End Here -->
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .footer-middle_area {
    text-align: center;
  }
}
</style>
