<template>
  <div class="row uren-brand_area">
    <div class="col-lg-12">
      <div class="text-center">
        <h2>Most Popular Car Makes</h2>
      </div>
      <div class="sp-img_area brand-home-slider">
        <carousel
          :responsive="{
            0: {
              items: 1,
              nav: false,
              dots: false,
              loop: true,
              autoplay: true,
              autoplayTimeout: 4000,
              autoplayHoverPause: false,
            },
            600: {
              items: 5,
              nav: false,
              dots: false,
              loop: true,
              autoplay: true,
              autoplayTimeout: 4000,
              margin: 60,
              autoplayHoverPause: false,
            },
          }"
        >
          <img src="/assets/images/brand/volkswagen.png" alt="Brand Image" />
          <img src="/assets/images/brand/mercedes-benz.png" alt="Brand Image" />
          <img src="/assets/images/brand/audi.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/volkswagen.png" alt="Brand Image" />
          <img src="/assets/images/brand/mercedes-benz.png" alt="Brand Image" />
          <img src="/assets/images/brand/audi.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/volkswagen.png" alt="Brand Image" />
          <img src="/assets/images/brand/mercedes-benz.png" alt="Brand Image" />
          <img src="/assets/images/brand/audi.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
          <img src="/assets/images/brand/volkswagen.png" alt="Brand Image" />
          <img src="/assets/images/brand/mercedes-benz.png" alt="Brand Image" />
          <img src="/assets/images/brand/audi.png" alt="Brand Image" />
          <img src="/assets/images/brand/ford.png" alt="Brand Image" />
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: { carousel },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
element {
  width: 200.8px;
}
.brand-home-slider {
  width: 80%;
  margin: 0px auto;
}
</style>
