<template>
  <div class="uren-banner_area uren-banner_area-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="banner-item img-hover_effect">
            <a href="">
              <img
                class="img-full"
                src="/assets/images/banner/1.jpg"
                alt="Uren's Banner"
              />
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="banner-item img-hover_effect">
            <a href="">
              <img
                class="img-full"
                src="/assets/images/banner/1.jpg"
                alt="Uren's Banner"
              />
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="banner-item img-hover_effect">
            <a href="">
              <img
                class="img-full"
                src="/assets/images/banner/1.jpg"
                alt="Uren's Banner"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
