<template>
<div>
<div v-if="!items.length">
            
  </div>
  <section style="padding: 10px 0px">
        <div class="bidz-container-list"  v-if="items.length" > 
          <div class="pdtListingWrap" v-for="item in items" :key="item.vehId" >
            <div class="row" >
              <!-- <div class="col-md-2">
                <div class="bidlistImagewrap">
                  <img
                    :src="path+ '/'+item.vehImage1" 
                    class="img-fluid img-responsive border5px"
                  />
                </div>
              </div> -->
              <div class="col-md-8 bidborderright">
                <div class="row">
                  <div class="bidlistDetailswrapwidth85">
                    <div class="bidlistDetailswrap">
                      <a href="#"
                        ><h4> 
                          {{ item.brandName}}
                          <span class="cModalname">
                            {{ item.modelName}}
                             </span>
                        </h4></a
                      >
                    </div>
                  </div>
                   <!-- <div class="bidlistDetailswrapwidth15">
                    <div>
                        <div class="button-bidzzz active"   @click="rmvfromWhishlist(item)">
                          <svg width="35px" height="25px" xmlns="http://www.w3.org/2000/svg">
                           <g fill="none" fill-rule="evenodd">
                             <path class="heart-stroke" d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z" fill="#0090E3"/>
                             <path class="heart-full" d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z" fill="red"/>
                             <path class="heart-lines" d="M26,4 L30.6852129,0.251829715" stroke="red" stroke-width="2" stroke-linecap="round"/>
                             <path class="heart-lines" d="M2.314788,4 L7.00000086,0.251829715" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 10.314788 1)"/>
                             <path class="heart-lines" d="M27,12 L33,12" stroke="red" stroke-width="2" stroke-linecap="round" />
                             <path class="heart-lines" d="M0,12 L6,12" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 7 1)"/>
                             <path class="heart-lines" d="M24,19 L28.6852129,22.7481703" stroke="red" stroke-width="2" stroke-linecap="round"/>
                             <path class="heart-lines" d="M4.314788,19 L9.00000086,22.7481703" stroke="red" stroke-width="2" stroke-linecap="round" transform="matrix(-1 0 0 1 14.314788 1)"/>
                           </g>
                          </svg>
                      </div>
                    </div>                    
                  </div> -->
                </div>
                <div class="row hidinglast2on768-991">
                  <div
                    class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="fas fa-tachometer-alt pdtListico"></i>
                        <div class="pdtListicon">
                          <span>KM </span>
                          <p class="fontWeightbidlist">
                             {{ item.kmClocked }} 
                             </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="fas fa-map-marker-alt pdtListico"></i>
                        <div class="pdtListicon">
                          <span>Location</span>
                          <p class="fontWeightbidlist">
                            {{ item.locName }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div
                    class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <span>Min. Inc.</span>
                          <p class="fontWeightbidlist">
                            {{item.aucMinBid}}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="far fa-clock pdtListico greenIcon"></i>
                        <div class="pdtListicon">
                          <span>Time Left</span>
                          <p class="fontWeightbidlist greenIcon">
                            {{item.aucMatDate}}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <div
                    class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <i class="far fa-calendar-alt pdtListico greenIcon"></i>
                        <div class="pdtListicon">
                          <span>End Date</span>
                          <p class="fontWeightbidlist greenIcon"> 
                            {{ item.aucMatDate }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                  >
                    <div class="">
                      <div>
                        <div class="text-center">
                          <a href=""
                            ><div class="numberplate">
                              {{item.vehRegNo}}                               
                            </div></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>                 
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center">
                  <div class="">
                    <span class="spanzz">CURRENT PRICE</span>
                    <h4 v-if="item.latestBidAmount == null">
                      ₹  0
                      </h4>
                      <h4 v-else>₹  {{item.latestBidAmount}}</h4>
                   <div class="marginbidtop">
                    <div class="marginbidtop">
                        <b-button v-b-modal.modalbidz class="bidBtn"  @click=" sendInfo(item)">BID NOW</b-button>
                    
                     </div> 
                     </div> 
                    <div class="marginbidtop">
                    <span class="spanzz">NUMBER OF BIDS</span>
                    <a class="bidColorbtn">{{ item.bidzCount }} BIDS</a>
                    </div>
                  </div>
                  <div>                   
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div class="overflow-auto"> 
               <div class="mt-3">                  
                  <b-pagination
                     v-model="currentPage"
                     :total-rows="totalRows" 
                     :per-page="perPage"
                     align="center"
                    
                     pills 
                     first-number
                     last-number                     
                  ></b-pagination>
                </div>
           </div> 
           <div>    
    <b-modal
      id="modalbidz"
      ref="modal"  hide-footer  v-if="currentUser"     
    >
      <form ref="form">
        <p for="bidamount" class="bidamountzz" v-if="selectedUser.latestBidAmount == null || selectedUser.latestBidAmount ==''">Latest Bid Amount: ₹ 0  </p>
        <p for="bidamount" class="bidamountzz" v-else>Latest Bid Amount:  ₹ {{ selectedUser.latestBidAmount }}</p>
         <input type="test" class="form-control" id="bidamount" v-model="bid" placeholder="Enter your bid amount" name="bidamont" style="width:100%;">
        <button type="button"  class="btn subBtnzz" @click="bidentry(selectedUser)" style="width: 100%;margin-top: 10px;">SUBMIT</button>
                      <div class="form-group" v-if="message == 'Success'"> 
                      <div  class="alert alert-warning" role="alert" v-on="$bvModal.hide('modalbidz')">{{message}}</div>
                       
                      </div>
                      <div class="form-group" v-else-if="message"> 
                      <div  class="alert alert-warning" role="alert">{{message}}</div>
                      </div>
      </form> 
      
    </b-modal>


    <b-modal
      id="modalbidz"
      ref="modal"  hide-footer  v-else-if="!currentUser"     
    >
      <form ref="form">
       
        <div>
                                    <p for="bidamount" class="bidamountzz">Login to BID </p>
                                 </div>
                                
                                <!-- <input type="test" class="form-control" id="bidamount" v-model="bid" placeholder="Enter your bid amount" name="bidamont" style="width:100%;"> -->
                            
                        <button type="submit"  class="btn subBtnzz" @click="gotologin()" style="width: 100%;margin-top: 10px;">Go To Login</button>

                    
      </form>   
    </b-modal>
  </div>
        </div>           
      </section>      
     </div>
</template>


<script>
import userService from '../services/user.service';
import user from '../models/localstorage'
import Config from  '../config';
import axios from "axios";
export default {
   name: "ProductCard",  
 
data()
{
  return{
      lists: [],     
        perPage: 3,
        currentPage: 1,
        path: Config.BASE_URL_IMG ,
         message:'',
         modalShow: false,
         bid:'',
         customerId:'',
         st:'',
         selectedUser: '', 
       
  }
},
computed: {
       currentUser() {     
      return this.$store.state.auth.user;
      
    },
    items () {     
      
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    totalRows () {
      return this.lists.length
    }
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login').catch(()=>{"mybidz"});
    }
    else {
      this.st = user; 
      this.getCarList();
      } 
      
  },
   methods :{
    
      sendInfo(item) {        
        this.selectedUser = item;
    },
          getCarList : function(){
          
        
            let tokens = this.st[0].token;
            let customerId = this.st[0].customerId;
               axios({                 
      method: "Get",
      url: Config.BASE_URL+'?sp=getCustomerBids&custId=' + customerId, headers: {'token': tokens }
    }).then(resp => {
       this.lists = resp.data.Data; 
    }).catch(err => {
      console.log(err.response) 
    })
     }, 
        bidentry(selectedUser){
       console.log(this.st[0].customerId);
        if( this.st && this.bid !=='' && this.bid !== null){
          let cusid = this.st[0].customerId;
        let bidamount = parseInt(this.bid); 
        userService.addbid(selectedUser.aucid,cusid,bidamount).then(response =>{
          console.log(response.data.Message);
         this.message = response.data.Message
         this.getCarList(); 
        }).catch(error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
                })  

        }
        else
        {
          this.message ='Field is requied'
        }
        

      },
   },
    
  
}
</script>

<style scoped>
.bidColorbtn {
    padding: 3px 20px; 
}
.bidBtn {
    
    padding: 3px 6px;
}
.marginbidtop {
    margin-top: 5px;
}
.spanzz
{
font-size: 12px;
}

.subBtnzz
{
      background-image: linear-gradient(to bottom right, #af0000, #ff3a3a);
    color: white;
    font-weight: 600;
}
.bidamountzz
{
  font-size: 20px;
    font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
          animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
          animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
          animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>