<template>

<div class="mp-sec">
 
     <div class="text-center">
                <h3 class="mb-30">Today's Auctions</h3>
            </div>
  
   <carousel
    :responsive="{
      0: {
        items: 1,
        nav: false,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        
      },
      600: {
        items: 4,
        nav: false,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
      },
    }"
  >
  
    <div class="">
      <article class="pdtSliderhome" >
        <div>
          <div>
            <img
              src="/assets/images/car-image.png"
              class="img-fluid"
              style="width: 100%"
            />
          </div>
          <div class="leftright10pad">
            <h6>Maruthi Susuki Wahon R</h6>
          </div>
            <div class="leftright10pad text-center pdtTileTimer">
                        <span class="">Time Left : 
                          <span id="counterDemo"></span>
                        </span>
                      </div>
          <div class="leftright10pad">
            <div class="pdtHomelistrow">
              <div class="pdtHomelistcolleft">Heading</div>
              <div class="pdtHomelistcolright">Heading</div>
            </div>
            <div class="pdtHomelistrow">
              <div class="pdtHomelistcolleft">Heading</div>
              <div class="pdtHomelistcolright">Heading</div>
            </div>
            <div class="pdtHomelistrow">
              <div class="pdtHomelistcolleft">Heading</div>
              <div class="pdtHomelistcolright">Heading</div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </carousel>
</div>
 
</template>
<script>
import carousel from "vue-owl-carousel";

export default {
  components: { carousel },
  
};
</script>


